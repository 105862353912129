import { BiStore } from "react-icons/bi";
import {
  BsLaptop,
  BsShieldCheck,
  BsNewspaper,
  BsYoutube,
} from "react-icons/bs";
import { PiBackpackBold } from "react-icons/pi";
import Link from "next/link";

const Layanan = () => {
  const layanans = [
    {
      icon: <BsLaptop />,
      title: "Layanan",
      deskripsi: "Layanan Perbaikan dan Penggantian Part",
      link: "/layanan",
    },
    {
      icon: <BiStore />,
      title: "Store",
    },
    {
      icon: <BsShieldCheck />,
      title: "Protection",
    },
    {
      icon: <PiBackpackBold />,
      title: "Academy",
    },
    {
      icon: <BsNewspaper />,
      title: "News",
      deskripsi: "Jadilah Paling Update",
    },
    {
      icon: <BsYoutube />,
      title: "Youtube",
      deskripsi: "Kenal Lebih Dekat Dengan Kami",
    },
  ];
  return (
    <div className="grid gap-4 lg:grid-cols-3 md:grid-cols-2 grid-cols-1">
      {layanans.map((item, index) => (
        <div
          key={index}
          className=" group hover:bg-red-600 transition duration-300 ease-in-out p-10 bg-white h-full rounded-lg"
        >
          <Link
            href={item.link || ""}
            target="_blank"
            className="inline-block mt-[15px] text-black"
          >
            <div className="before:absolute before:content-[''] before:h-full before:w-full before:bg-[#eee] before:rounded-[50px] before:z-[1] before:top-2.5 before:-right-5 before:transition-all before:duration-300 w-[48px] h-[48px] relative mb-[50px] text-[#222] lg:text-5xl text-2xl relative z-[2] transition-all duration-300 ease-in-out">
              <span className="relative text-6xl z-20">{item.icon}</span>
            </div>
            <h3 className="font-semibold group-hover:text-white mb-[20px] pb-[8px] text-xl inline-block relative border-b-4 border-[#eee]">
              {item.title}
            </h3>
            <p className="leading-normal group-hover:text-white text-lg mb-0">
              {item.deskripsi}
            </p>
            {item.link && (
              <p className="group-hover:text-white mt-4">Read More</p>
            )}
          </Link>
        </div>
      ))}
    </div>
  );
};

export default Layanan;
