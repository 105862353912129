"use client";

import { useEffect, useState } from "react";
import axios from "axios";

interface Data {
  id: number;
  name: string;
  image: string;
  updated_at: string;
}

interface ApiResponse {
  status: number;
  data: Data[];
}

export const useFetchGalleries = () => {
  const [galleries, setGalleries] = useState<Data[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get<ApiResponse>(
          "https://admin.macspace.id/public/api/galleries"
        );
        setGalleries(response.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  return galleries;
};
