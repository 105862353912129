import Timelines from "./Timelines";
import Layanan from "./Layanan";
import ConntrollerInput from "./ControllerInput";
import GalleryCard from "./GalleryCard";

const Fragments = {
  Timelines,
  Layanan,
  Controller: ConntrollerInput,
  GalleryCard,
};

export default Fragments;
