import Image from "next/image";
import { cn } from "@/lib/utils";

interface TimelinesProps {
  type: string;
  title: string;
  content: string;
  image: string;
}

const Timelines: React.FC<TimelinesProps> = (props) => {
  const { title, content, image, type } = props;
  switch (type) {
    case "right":
      return (
        <div className="timeline-item mt-5 pt-4">
          <div className="grid sm:grid-cols-2">
            <div className="md:order-1 order-2">
              <div className="event event-description-left ltr:float-left rtl:float-right ltr:text-right rtl:text-left md:me-7">
                <div className="md:text-end">
                  <h5 className="text-lg dark:text-white mb-1 font-medium">
                    {title}
                  </h5>
                  <p className="timeline-subtitle mt-3 mb-0 text-slate-400">
                    {content}
                  </p>
                </div>
              </div>
            </div>
            <div className="md:order-2 order-1">
              <div className=" duration duration-right md:ms-7 relative">
                <Image
                  src={image}
                  alt="timeline"
                  width={100}
                  loading="lazy"
                  placeholder="blur"
                  blurDataURL={image}
                  height={100}
                  className="h-64 w-64 float-left"
                />
              </div>
            </div>
          </div>
        </div>
      );
    case "left":
      return (
        <div className="timeline-item mt-5 pt-4">
          <div className="grid sm:grid-cols-2">
            <div className="mt-4 mt-sm-0">
              <div className="duration date-label-left ltr:float-right rtl:float-left md:me-7 relative">
                <Image
                  src={image}
                  alt="timeline"
                  width={100}
                  height={100}
                  className="h-64 w-64 md:float-right"
                />
              </div>
            </div>
            <div className="mt-4 mt-sm-0">
              <div className="event event-description-right ltr:float-left rtl:float-right ltr:text-left rtl:text-right md:ms-7">
                <h5 className="text-lg dark:text-white mb-1 font-medium">
                  {title}
                </h5>
                <p className="timeline-subtitle mt-3 mb-0 text-slate-400">
                  {content}
                </p>
              </div>
            </div>
          </div>
        </div>
      );
  }
};

export default Timelines;
