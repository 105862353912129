"use client";

import { usePathname } from "next/navigation";

interface JumbotronProps {
  text: string;
}

const Jumbotron: React.FC<JumbotronProps> = (props) => {
  const { text } = props;
  const pathname = usePathname();

  return (
    <section
      className={`relative ${
        pathname.match("/contact")
          ? "bg-[url('/images/jumbotron/macspace_workshop.webp')]"
          : "bg-[url('/images/jumbotron/ozy-macspace.webp')]"
      } z-[1] table w-full py-36  bg-center bg-no-repeat bg-cover`}
    >
      <div className="absolute inset-0 bg-black opacity-70"></div>
      <div className="container relative mx-auto">
        <div className="grid grid-cols-1 pb-8 text-center mt-10">
          <h1 className="md:text-4xl uppercase text-3xl font-medium md:leading-normal tracking-wode leading-normal text-white">
            {text}
          </h1>
        </div>
      </div>
    </section>
  );
};

export default Jumbotron;
